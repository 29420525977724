html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: overlay;
}

html[data-scroll="88"] .navbarTop {
  position: fixed;
  top: 0;
}
